import { DaoCreationForm } from 'typings/forms';

import { parseJson } from 'utils/parse-json';

interface FormStorage {
  version: string;
  formData: DaoCreationForm;
}

const FORM_STORAGE_NAME = 'daoFormStorage';
export const FORM_STORAGE_VERSION = '1.1';

export function getFormLocalStorage (): FormStorage | null {
  const item = window.localStorage.getItem(FORM_STORAGE_NAME);
  const parsedForm = item ? parseJson<FormStorage>(item) : null;

  return parsedForm;
}

export function setFormLocalStorage (form: DaoCreationForm) {
  const storage = {
    version: FORM_STORAGE_VERSION,
    formData: form
  };
  window.localStorage.setItem(FORM_STORAGE_NAME, JSON.stringify(storage));
}

export function removeFormLocalStorage () {
  window.localStorage.removeItem(FORM_STORAGE_NAME);
}
