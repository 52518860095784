import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import logo from 'assets/img/logo.png';
import Network from 'navigation/Header/components/Network';

import packageJson from '../../../package.json';

import EcosystemLinks from './components/EcosystemLinks';
import References from './components/References/References';
import VersionModal from './components/VersionModal';
import { SidebarContainer } from './styles';

function Sidebar ({ open, onClose }: { open: boolean; onClose: () => void }) {
  const { t } = useTranslation();
  const [versionModalOpen, setVersionModalOpen] = useState(false);

  return (
    <SidebarContainer $open={open}>
      <div className="sidebar__overlay" onClick={onClose} />

      <div className="sidebar" onClick={onClose}>
        <div className="sidebar__content">
          <Link to="/" className="sidebar__logo-link">
            <img
              className="sidebar__logo"
              alt="Q Logo"
              src={logo}
            />
            <span className="sidebar__project-name">
              {t('DAO_FACTORY')}
            </span>
          </Link>

          <div className="sidebar__main">
            <div className="sidebar__links">
              <div className="sidebar__links-item">
                <Network className="sidebar__network" />
              </div>
            </div>

            <References />
            <EcosystemLinks />
          </div>
        </div>

        <div className="sidebar__footer">
          <button className="sidebar__footer-link text-md" onClick={() => setVersionModalOpen(true)}>
            {packageJson.version}
          </button>

          <Link to="/data-privacy" className="sidebar__footer-link text-md">
            {t('DATA_PRIVACY')}
          </Link>

          <Link to="/imprint" className="sidebar__footer-link text-md">
            {t('IMPRINT')}
          </Link>
        </div>

        <VersionModal open={versionModalOpen} onClose={() => setVersionModalOpen(false)} />
      </div>
    </SidebarContainer>
  );
}

export default Sidebar;
