import { ErrorHandler } from 'helpers';

export function parseJson<T = unknown | unknown[]> (raw: string): T {
  try {
    return JSON.parse(raw);
  } catch (error) {
    ErrorHandler.processWithoutFeedback(error);
    return (raw.startsWith('[') ? [] : {}) as T;
  }
}
