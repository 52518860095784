import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DaoCreationForm } from 'typings/forms';

interface DaoCreateState {
  daoForm: DaoCreationForm | null;
  daoRegistryAddress: string;
  constitutionHash: string;
}

const initialState: DaoCreateState = {
  daoForm: null,
  daoRegistryAddress: '',
  constitutionHash: '',
};

const daoSlice = createSlice({
  name: 'dao',
  initialState,
  reducers: {
    setDaoForm: (state, { payload }: PayloadAction<DaoCreationForm | null>) => {
      state.daoForm = payload;
    },
    setDaoRegistryAddress: (state, { payload }: PayloadAction<string>) => {
      state.daoRegistryAddress = payload;
    },
    setConstitutionHash: (state, { payload }: PayloadAction<string>) => {
      state.constitutionHash = payload;
    }
  },

});

export const { setDaoForm, setDaoRegistryAddress, setConstitutionHash } = daoSlice.actions;
export default daoSlice.reducer;
