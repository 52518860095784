import { media } from '@q-dev/q-ui-kit';
import styled from 'styled-components';

export const StyledHeader = styled.header`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderPrimary};
  height: 72px;
  background-color: ${({ theme }) => theme.colors.backgroundPrimary};
  
  .header__content {
    display: grid;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 16px 32px;
    grid-template-columns: repeat(3, 1fr);

    ${media.lessThan('medium')} {
      padding: 16px;
      grid-template-columns: 1fr 4fr;
    }
  }
 
  .header__project {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    ${media.lessThan('medium')} {
      display: none;
    }
  }

  .header__project-logo {
    width: 36px;
    height: 32px;
  }

  .header__project-name {
    font-size: 28px;
  }

  .header__network {
    ${media.lessThan('medium')} {
      display: none;
    }
  }

  .header__left {
    display: flex;
    flex-direction: row-reverse;
    gap: 8px;
    justify-content: flex-end;
  }

  .header__menu {
    display: inline-flex;
  }

  .header__actions {
    display: flex;
    gap: 8px;
    justify-content: flex-end;

  }

  .header__transactions {
    ${media.lessThan('large')} {
      display: none;
    }
  }
`;
