import type { ERC721EnumerableUpgradeable } from '@q-dev/gdk-sdk';
import { ERC721EnumerableUpgradeable__factory as ERC721 } from '@q-dev/gdk-sdk/lib/ethers-contracts/factories/ERC721EnumerableUpgradeable__factory';
import type { providers, Signer } from 'ethers';
import { ErrorHandler } from 'helpers';

import { ERC_721_INTERFACE_UPGRADEABLE_ID } from 'constants/boundaries';

export let erc721ContractInstance: ERC721EnumerableUpgradeable | null = null;

export const getErc721ContractInstance = (address: string, provider: Signer | providers.Provider) => {
  erc721ContractInstance = ERC721.connect(address, provider);
  return erc721ContractInstance;
};

export async function checkErc721 (address: string, provider: providers.Provider) {
  getErc721ContractInstance(address, provider);
  if (!erc721ContractInstance) return false;

  try {
    const isUpgradeable = await checkErc721Interface(ERC_721_INTERFACE_UPGRADEABLE_ID);
    return isUpgradeable;
  } catch (error) {
    ErrorHandler.processWithoutFeedback(error);
    return false;
  }
}

export const checkErc721Interface = async (interfaceId: string) => {
  if (!erc721ContractInstance) return false;
  try {
    return await erc721ContractInstance.supportsInterface(interfaceId);
  } catch (error) {
    ErrorHandler.processWithoutFeedback(error);
    return false;
  }
};

export async function getErc721TokenDetails (address: string, provider: providers.Provider) {
  try {
    getErc721ContractInstance(address, provider);
    if (!erc721ContractInstance) return null;

    const [name, symbol, totalSupply, balance] = await Promise.all([
      erc721ContractInstance.name(),
      erc721ContractInstance.symbol(),
      erc721ContractInstance.totalSupply(),
      erc721ContractInstance.balanceOf(address),
    ]);

    return {
      name,
      symbol,
      decimals: '0',
      totalSupply: totalSupply.toString(),
      balance: balance.toString(),
    };
  } catch (error) {
    ErrorHandler.processWithoutFeedback(error);
    return null;
  }
}
