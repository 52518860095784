import { getValidatorValue } from '@q-dev/form-hooks';
import { ReservedDAOContractNameParts } from '@q-dev/gdk-sdk';
import { toBigNumber } from '@q-dev/utils';
import { isAddress } from 'helpers';
import i18n from 'i18next';
import { isBoolean, isDate, isEmpty, isNaN, isNumber } from 'lodash';

import { ZERO_ADDRESS } from 'constants/boundaries';

const RESERVED_DAO_CONTRACT_NAME_PARTS_LIST = Object.values(ReservedDAOContractNameParts);
const HASH_REGEX = /^0x[a-fA-F0-9]{64}$/;
const INTEGER_REGEX = /^[0-9]+$/;
const NAME_REGEX = /^[A-Za-z0-9]+[A-Za-z0-9_\-. ]*[A-Za-z0-9]+$/;
const SYMBOL_REGEX = /^[A-Za-z0-9]+$/;
export const URL_REGEX = /^https?:\/\/(www\.)?[-äöüa-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-äöüa-zA-Z0-9()@:%_+.~#?&//=]*)/;

interface ValidationResult {
  isValid: boolean;
  message: string;
}
type ValidatorValue = string | number | boolean | Date | File | null;
type Validator<T extends ValidatorValue = ValidatorValue, U = unknown> = (val: T, form?: U) => ValidationResult;
type ValidatorFn<U, T extends ValidatorValue = ValidatorValue> = (val: U | ((form: unknown) => T)) => Validator<T>;

export const required: Validator = (val) => ({
  isValid: !isEmpty(val) || isNumber(val) || isDate(val) || isBoolean(val) || val instanceof File,
  message: i18n.t('VALIDATION_REQUIRED')
});

export const requiredIf: ValidatorFn<(val: ValidatorValue, form: unknown) => boolean> = predicate => (val, form) => {
  return {
    isValid: !predicate(val, form) || required(val).isValid,
    message: i18n.t('VALIDATION_REQUIRED')
  };
};

export const amount: ValidatorFn<string | number> = max => (val, form) => {
  const value = toBigNumber(String(val));
  const zero = toBigNumber(0);
  const validatorValue = toBigNumber(String(getValidatorValue(max, form)));

  if (value.comparedTo(zero) === 0) {
    return {
      isValid: false,
      message: i18n.t('VALIDATION_AMOUNT_COMPARED_ZERO')
    };
  }

  if (validatorValue.comparedTo(toBigNumber(0)) === 0) {
    return {
      isValid: false,
      message: i18n.t('VALIDATION_AVAILABLE_AMOUNT_ZERO')
    };
  }

  return {
    isValid: value.comparedTo(validatorValue) <= 0,
    message: i18n.t('VALIDATION_MAX_AMOUNT', { max })
  };
};

export const min: ValidatorFn<string | number> = min => (val, form) => {
  const value = toBigNumber(String(val));
  const validatorValue = toBigNumber(String(getValidatorValue(min, form)));

  return {
    isValid: value.comparedTo(validatorValue) >= 0,
    message: i18n.t('VALIDATION_MIN_VALUE', { min })
  };
};

export const max: ValidatorFn<string | number> = max => (val, form) => {
  const value = toBigNumber(String(val));
  const validatorValue = toBigNumber(String(getValidatorValue(max, form)));

  return {
    isValid: value.comparedTo(validatorValue) <= 0,
    message: i18n.t('VALIDATION_MAX_VALUE', { max })
  };
};

export const url: Validator = val => ({
  isValid: !val || URL_REGEX.test(String(val)),
  message: i18n.t('VALIDATION_URL')
});

export const address: Validator = val => ({
  isValid: !val || isAddress(val.toString()),
  message: i18n.t('VALIDATION_ADDRESS')
});

export const nonZeroAddress: Validator<string> = val => ({
  isValid: !val || (isAddress(val) && val !== ZERO_ADDRESS),
  message: i18n.t('VALIDATION_ADDRESS')
});

export const hash: Validator = val => ({
  isValid: !val || HASH_REGEX.test(String(val)),
  message: i18n.t('VALIDATION_HASH')
});

export const name: Validator = val => ({
  isValid: !val || NAME_REGEX.test(String(val)),
  message: i18n.t('VALIDATION_NAME')
});

export const reservedNameParts: Validator = val => {
  return {
    isValid: !val || RESERVED_DAO_CONTRACT_NAME_PARTS_LIST.every((i) => !String(val).includes(i)),
    message: i18n.t('VALIDATION_RESERVED_NAME_PARTS')
  };
};

export const currentHash: ValidatorFn<string> = hash => val => ({
  isValid: !val || val === hash,
  message: i18n.t('VALIDATION_CURRENT_HASH')
});

export const percent: Validator = val => ({
  isValid: !val || (Number(val) >= 0 && Number(val) <= 100),
  message: i18n.t('VALIDATION_PERCENT')
});

export const futureDate: Validator = val => ({
  isValid: !val || new Date(val.toString()) > new Date(),
  message: i18n.t('VALIDATION_FUTURE_DATE')
});

export const maxLength: ValidatorFn<string | number> = max => (val, form) => {
  const value = toBigNumber(String(val).length);
  const validatorValue = toBigNumber(String(getValidatorValue(max, form)));

  return {
    isValid: value.comparedTo(validatorValue) <= 0,
    message: i18n.t('VALIDATION_MAX_LENGTH', { max })
  };
};

export const minLength: ValidatorFn<string | number> = min => (val, form) => {
  const value = toBigNumber(String(val).length);
  const validatorValue = toBigNumber(String(getValidatorValue(min, form)));

  return {
    isValid: value.comparedTo(validatorValue) >= 0,
    message: i18n.t('VALIDATION_MIN_LENGTH', { min })
  };
};

export const number: Validator = val => ({
  isValid: isNumber(Number(val)) && !isNaN(Number(val)),
  message: i18n.t('VALIDATION_NUMBER_VALUE')
});

export const integer: Validator = val => ({
  isValid: !val || (INTEGER_REGEX.test(String(val)) && Number.isInteger(Number(val))),
  message: i18n.t('VALIDATION_INTEGER_NUMBER_VALUE')
});

export const symbol: Validator = val => ({
  isValid: !val || SYMBOL_REGEX.test(String(val)),
  message: i18n.t('VALIDATION_SYMBOL')
});

export const decimals: ValidatorFn<number | ((form: unknown) => number)> = decimals => (val, form) => {
  const validatorValue = Number(getValidatorValue(decimals, form));
  const decimalSplit = String(val).split('.')[1] || '';

  return {
    isValid: decimalSplit.length <= validatorValue,
    message: i18n.t('VALIDATION_DECIMALS', { decimals: validatorValue })
  };
};
