
import type { QRC20 } from '@q-dev/gdk-sdk';
import { QRC20__factory as Erc20 } from '@q-dev/gdk-sdk/lib/ethers-contracts/factories/QRC20__factory';
import type { providers, Signer } from 'ethers';
import { ErrorHandler } from 'helpers';

export let erc20ContractInstance: QRC20 | null = null;

export const getErc20ContractInstance = (address: string, provider: Signer | providers.Provider) => {
  erc20ContractInstance = Erc20.connect(address, provider);
  return erc20ContractInstance;
};

export const getErc20TokenDetails = async (address: string, provider: providers.Provider) => {
  try {
    getErc20ContractInstance(address, provider);
    if (!erc20ContractInstance) return null;

    const [name, symbol, decimals, owner, totalSupply, balance] = await Promise.all([
      erc20ContractInstance.name(),
      erc20ContractInstance.symbol(),
      erc20ContractInstance.decimals(),
      erc20ContractInstance.owner(),
      erc20ContractInstance.totalSupply(),
      erc20ContractInstance.balanceOf(address),
    ]);

    return {
      name,
      symbol,
      decimals: decimals.toString(),
      owner,
      totalSupply: totalSupply.toString(),
      balance: balance.toString(),
    };
  } catch (error) {
    ErrorHandler.processWithoutFeedback(error);
    return null;
  }
};
