import { useSelector } from 'react-redux';

import { combineReducers, configureStore } from '@reduxjs/toolkit';

import dao from './dao/reducer';
import transaction from './transaction/reducer';

export const store = configureStore({
  reducer: combineReducers({
    transaction,
    dao,
  }),
});

export type AppState = ReturnType<typeof store.getState>;

export function useAppSelector<T> (selector: (state: AppState) => T) {
  return useSelector(selector);
}

export function getState () {
  return store.getState();
}
