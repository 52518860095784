import { useCallback, useRef, useState } from 'react';

import {
  Chain,
  ChainId,
  createProvider,
  CreateProviderOpts,
  IProvider,
  ProviderProxyConstructor,
  RawProvider,
  TransactionResponse,
  TxRequestBody,
} from '@distributedlab/w3p';
import { RelayProvider } from '@opengsn/provider';
import { errors } from 'errors';
import { ethers, providers, Signer } from 'ethers';
import { ErrorHandler } from 'helpers';
import { ProviderWrapper } from 'typings';

import { chainIdToNetworkMap, gsnConfigMap } from 'constants/config';
import { FALLBACK_PROVIDER_NAMES } from 'constants/providers';

export const useProvider = (): ProviderWrapper => {
  const _provider = useRef<IProvider | null>(null);
  const [currentProvider, setCurrentProvider] =
    useState<ethers.providers.Web3Provider | ethers.providers.JsonRpcProvider>();
  const [currentSigner, setCurrentSigner] = useState<Signer>();
  const [gsnSigner, setGsnSigner] = useState<Signer>();
  const [providerReactiveState, setProviderReactiveState] = useState(() => {
    return {
      address: _provider.current?.address || '',
      isConnected: _provider.current?.isConnected || false,
      chainId: _provider.current?.chainId || '',
      chainType: _provider.current?.chainType,
      providerType: _provider.current?.providerType,
    };
  });

  const connect = async (): Promise<void> => _provider.current?.connect();

  const disconnect = async () => {
    if (_provider.current?.disconnect) {
      await _provider.current.disconnect();
    }

    _provider.current?.clearHandlers();
    _provider.current = null;
    setCurrentSigner(undefined);
    setCurrentProvider(undefined);
    setGsnSigner(undefined);
  };

  const addChain = async (chain: Chain): Promise<void> =>
    _provider.current?.addChain?.(chain);

  const switchChain = async (chainId: ChainId): Promise<void> =>
    _provider.current?.switchChain?.(chainId);

  const switchNetwork = async (chainId: ChainId, chain?: Chain) => {
    try {
      await switchChain(chainId);
    } catch (error) {
      if (chain &&
        (error instanceof errors.ProviderInternalError ||
          error instanceof errors.ProviderChainNotFoundError)
      ) {
        await addChain(chain);
      } else {
        throw error;
      }
    }
  };

  const signAndSendTx = async (
    txRequestBody: TxRequestBody,
  ): Promise<TransactionResponse> =>
    _provider.current?.signAndSendTx?.(txRequestBody) ?? '';

  const signMessage = async (message: string): Promise<string> =>
    _provider.current?.signMessage?.(message) ?? '';

  const getHashFromTxResponse = (txResponse: TransactionResponse): string =>
    _provider.current?.getHashFromTx?.(txResponse) ?? '';

  const getTxUrl = (chain: Chain, txHash: string): string =>
    _provider.current?.getTxUrl?.(chain, txHash) ?? '';

  const getAddressUrl = (chain: Chain, address: string): string =>
    _provider.current?.getAddressUrl?.(chain, address) ?? '';

  const initGSN = useCallback(async (rawProvider: RawProvider) => {
    const gsnConfig = gsnConfigMap[chainIdToNetworkMap[Number(_provider.current?.chainId)]];

    if (!_provider.current?.address || !gsnConfig) {
      setGsnSigner(undefined);
      return;
    };

    try {
      const relayProvider = await RelayProvider.newProvider({
        provider: rawProvider as unknown as RelayProvider,
        config: {
          loggerConfiguration: { logLevel: 'error' },
          paymasterAddress: gsnConfig.paymaster,
          maxRelayNonceGap: 10000,
          preferredRelays: gsnConfig.relays,
        },
      }).init();

      const gsnProvider = new providers.Web3Provider(relayProvider as unknown as providers.ExternalProvider);
      const gsnSigner = gsnProvider.getSigner();
      setGsnSigner(gsnSigner);
    } catch (e) {
      ErrorHandler.processWithoutFeedback(e);
      setGsnSigner(undefined);
    }
  }, []);

  const init = useCallback(async (
    providerProxyConstructor: ProviderProxyConstructor,
    createProviderOpts: CreateProviderOpts<FALLBACK_PROVIDER_NAMES>,
  ) => {
    _provider.current?.clearHandlers();

    _provider.current = await createProvider(
      providerProxyConstructor,
      {
        providerDetector: createProviderOpts.providerDetector,
        listeners: {
          ...createProviderOpts.listeners,
          onAccountChanged: (e) => {
            createProviderOpts?.listeners?.onAccountChanged?.(e);
            _updateProviderState();
            _updateProvidersAndSigner();
          },
          onChainChanged: (e) => {
            createProviderOpts?.listeners?.onChainChanged?.(e);
            _updateProviderState();
            _updateProvidersAndSigner();
          },
          onConnect: (e) => {
            createProviderOpts?.listeners?.onConnect?.(e);
            _updateProviderState();
          },
          onDisconnect: (e) => {
            createProviderOpts?.listeners?.onDisconnect?.(e);
            _updateProviderState();
            _updateProvidersAndSigner();
          },
        }
      },
    );

    await _updateProvidersAndSigner();
    _updateProviderState();
    return {
      isConnected: _provider.current?.isConnected || false
    };
  }, []);

  const _updateProvidersAndSigner = async () => {
    const rawProvider = _provider.current?.rawProvider;

    if (rawProvider) {
      const web3Provider = rawProvider instanceof providers.JsonRpcProvider
        ? rawProvider
        : new providers.Web3Provider(rawProvider as providers.ExternalProvider);
      if (_provider.current?.isConnected) {
        const signer = web3Provider.getSigner();
        setCurrentSigner(signer);
      } else {
        setCurrentSigner(undefined);
      }
      setCurrentProvider(web3Provider);
      await initGSN(rawProvider);
    } else {
      setCurrentSigner(undefined);
      setCurrentProvider(undefined);
      setGsnSigner(undefined);
    }
  };

  const _updateProviderState = () => {
    setProviderReactiveState({
      address: _provider.current?.address || '',
      isConnected: _provider.current?.isConnected || false,
      chainId: _provider.current?.chainId || '',
      chainType: _provider.current?.chainType,
      providerType: _provider.current?.providerType,
    });
  };

  return {
    init,
    currentProvider,
    currentSigner,
    gsnSigner,
    ...providerReactiveState,
    connect,
    disconnect,
    addChain,
    switchNetwork,
    signMessage,
    signAndSendTx,
    getTxUrl,
    getHashFromTxResponse,
    getAddressUrl,
  };
};
