import { toBigNumber } from '@q-dev/utils';
import { BigNumberish, utils } from 'ethers';

export function isAddress (value: string) {
  return utils.isAddress(value.toLowerCase());
}

export function fromWei (value: BigNumberish, unitName?: BigNumberish) {
  return toBigNumber(utils.formatUnits(value, unitName)).toFixed();
}

export function toWei (value: string, decimals = 18) {
  return toBigNumber(value)
    .multipliedBy(10 ** decimals)
    .toFixed(0, 1); // ROUND_DOWN = 1
}
